/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import _, { isNull, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { Img } from 'react-image'
import {
  CommentContent, CommentSection, StyledLink, TimeContent, ViewMoreLink, CommentsContainer, TextFieldContainer, SingleComment,
  ImageWrapper,
  TimeContentV2, InputFieldContainer, CommentsWrapper
} from './styles';
import { FieldContainer,/* InputImage*/ } from '../EventDetails/styles';
import { DownAngle, RecognitionSection, RecognitionText, SendButton, CommentBoxWrap, ReplyTextPopup, MaxText, DownAngleV2 } from '../SocialFeedsV2/styles';
import Image from '../Image';
import { convertDateInTimezone, convertUnicode, applyHighlights, getDecodedString, checkImage, getOrientation, resetOrientation, convertMonthFromDate } from '../../utils/methods';
import { getUserName, getUserProfileImage, getUserId, getUserPosts, likeUnlikePostComment, disableButton, likeUnlikeCommentReply } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
const DeleteCommentPopup = React.lazy(() => import('./deleteCommentPopup'));
import ReplySectionTextArea from '../SocialFeedsV2/ReplySectionTextArea';
import SocialFeedsEmojiPicker from '../SocialFeedsEmojiPicker';
import DOMPurify from 'dompurify';
import { IconContainer } from '../EventDetails/styles';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyTextField: '',
      replyText: '',
      replyCount: 3,
      isUpdatedImage: false,
      mentionedUsers: [],
      users: [],
      showGivenShoutout: false,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: 0,
      exemplifyID: null,
      ShowEmojiContainer: false,
      displayEmojiPicker: false,
      isPostLiked: props.feed.own_like_post === 1,
      clicked: false,
      likeStatus: false,
      commentId: '',
      showDeleteCommentPopup: false,
      DeleteComment: false,
      imgSrcV2: [],
      messageLength: 0,
      deleteCommentId: null,
      position: { start: 0, end: 0 },
      initialReply:true,
      tagUserName:''
    }
    this.myref = React.createRef();
    this.emojiref = React.createRef();
  }


  handleClick = (event) => {
    if ((this.state.replyTextField === '') && !isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && !isNull(this.myref.current) && !isUndefined(this.myref.current) && this.myref.current.contains(event.target))) {
      this.setState({ ShowEmojiContainer: false });
    }
  };
  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      this.setState({ displayEmojiPicker: false });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    // this.replyClick && document.addEventListener("mousedown", this.replyClick);
    // document.addEventListener("mousedown", this.handleEmojiClick);
    if (!this.props.userName) this.props.fetchUserName();
    if (!this.props.profileImage) this.props.fetchUserProfileImage();
    if (!this.props.userId) this.props.fetchUserId();
    this.checkBase64(this.props.profileImage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.replySuccess) {
      this.setState({
        replyText: '',
        replyTextField: ''
      });
    }
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
    document.removeEventListener("mousedown", this.handleEmojiClick);
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }
   
  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";</span>");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  onChangeValue = (e, name) => {
    let position = e ? this.getPosition(e.target) : { start: name.length, end: name.length };
    let text = e ? e.target.value : name;
  
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 500) {
      this.setState({
        replyText: text,
        position: position
      });
    } else {
      // Prevent adding more characters beyond the limit
      this.setState({
        replyText: text.slice(0, 500),
        position: position
      });
    }
  };

  addInspirationV2 = (native) => {
    let newText = this.state.replyText.slice(0, this.state.position.start) + native + this.state.replyText.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + native.length, end: this.state.position.end + native.length },
      replyText: newText,
    });
  };

  addReply = (e, commentId) => {
    e.preventDefault();
    const { users, userIdArray, replyText, taggedUser, mentionedUsers, exemplifyID, imgSrcV2 } = this.state;
    const { postReply, id } = this.props;
    let shoutout = {
      // exemplifies: mentionedUsers.length > 0 ? mentionedUsers : userIdArray
    }
    let reply = this.toCodePoints(replyText), shoutOutUserName;
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray) && users && taggedUser) {
      shoutOutUserName = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    let exemplify_id = exemplifyID;
    // let image = "";
    reply=this.addhightLight(reply, this.state.tagUserName);
    postReply(commentId, reply, id, shoutout, shoutOutUserName, exemplify_id, imgSrcV2.length >= 1 ? imgSrcV2[0] : '');
    this.setState({
      showTextAreaFor: null,
      reply: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      thankYou: [],
      congrats: [],
      jobWellDone: [],
      taggedUser: '',
      userIdArray: [],
      users: [],
      imgSrcV2: [],
      messageLength: 0,
    });
  };


  removeImage = () => {
    this.setState({
      imgSrcV2: [],
      messageLength: 0
    });
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    this.setState({
      messageLength: e.target.value.length >= 1 ? "1" : '0'
    });
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrcV2: [reader.result],
            refreshFileReader: true,
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrcV2: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('replyPhoto').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('replyPhoto').value = '';
    }
  };

  showTextField = (id,name) => {
    const { replyTextField } = this.state;
    this.setState({
      replyTextField: replyTextField === id ? '' : id,
      replyText:name
    })
  };

  showMoreReplies = (replyCount) => {
    this.setState({
      replyCount
    });
  };

  showProfileImage = (comment, isOwn) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isOwn) {
      if (isUpdatedImage) {
        return (<img src={profileImage} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if (userId === comment.uid) {
      if (isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" />)
      }
      else {
        return (<Image image={profileImage} alt="avatar" />)
      }
    }
    else {
      return (<Image image={comment.profile_image} alt="avatar" />)
    }
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      replyText: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplifies_id) => {
    this.setState({
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplifies_id ? exemplifies_id : null,
      mentionedUsers: mentionedUsers,
      exemplifyID: exemplifies_id ? exemplifies_id : null
    });
    this.displayEmojiContainer();
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.addInspirationV2(native);
  }

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '') {
      return false;
    }
    return true;
  };

  displayEmojiContainer = () => {
    this.setState((prev) => ({
      ShowEmojiContainer: !prev.ShowEmojiContainer
    }));
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmojiPicker: !prev.displayEmojiPicker
    }))
  }

  likeUnlikePost = (id, likestatus) => {
    const { likeUnlikePostComment, feed, disableLikeButton } = this.props;
    const likeData = {
      comment_id: id,
      flag: likestatus === 1 ? 'unlike' : 'like',
    };
    this.setState({
      commentId: id
    });
    disableLikeButton();
    likeUnlikePostComment(likeData, feed.id);
  };
  likeUnlikePostCommentReply = (id, likestatus) => {
    const { likeUnlikeCommentReply, feed, disableLikeButton } = this.props;
    const likeData = {
      reply_id: id,
      flag: likestatus === 1 ? 'unlike' : 'like',
    };
    this.setState({
      commentId: id
    });
    disableLikeButton();
    likeUnlikeCommentReply(likeData, feed.id);
  };

  addhightLight = (string, parameter)=> {
    const strWords = string.split(' '); // split string into words
    const paramWords = parameter.split(' '); // split parameter into words
    if (strWords.slice(0, paramWords.length).join(' ') === parameter) {
      for (let i = 0; i < paramWords.length; i++) {
        strWords[i] = '<b>' + strWords[i] + '</b>'; // add bold tags to matching words
      }
    }
    return strWords.join(' '); // join words back into a string
  }
  
  renderTextArea = (value, id, name) => {
    const { noFloat, enableTrigger } = this.props;
    if (name && !this.state.replyText && this.state.initialReply) {
      this.setState({
        initialReply:false,
        tagUserName:name
      })
      this.onChangeValue(false, name);
    }
    return (
      <TextFieldContainer noFloat={noFloat} >
        <CommentContent noFloat={noFloat}>
          <ImageWrapper comment={1} >
            {this.showProfileImage('', true)}
          </ImageWrapper>
          <InputFieldContainer comment name={name} height={this.state.showGivenShoutout || this.state.imgSrcV2.length ? 'auto' : 'auto'} padding={'0px'} flex={1} border="none !important" style={{borderRadius:"20px !important"}}>
            <ReplySectionTextArea
              replyCommentName={name}
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              inspirationQuote={value}
              updateText={this.updateText}
              users={this.state.users}
              userIdArray={this.state.userIdArray}
              placeholder={" Write your reply"}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
              ShowEmojiContainer={this.state.ShowEmojiContainer}
              myref={this.myref}
              showEmojiPicker={this.showEmojiPicker}
              displayEmojiPicker={this.state.displayEmojiPicker}
              closeEmojiContainer={this.displayEmojiContainer}
              emojiRef={this.emojiref}
              comments={this.props.comments}
              selectedImage={this.state.imgSrcV2[0]}
              removeImage={this.removeImage}
              addInspirationV2={this.addInspirationV2}
            />
          </InputFieldContainer>
        </CommentContent>
        <RecognitionSection float={'right'} width={'35%'} height={'none'} display={'flex-end'} reply={1} style={{width:"100%"}}>
          <div style={{display: "flex", margin: "auto", marginBottom: "5px", marginLeft: "50px", marginTop:"10px"}}>
            <div style={{width:"46px", height:"46px", border:"1px solid #005C874D", display:"flex", borderRadius:"50%",marginRight:"0px"}}>
              <img style={{ marginTop: '0px', cursor: 'pointer', width: '27px', height: '27px', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/Emoji.png"}></img>
            </div>
            <SendButton disabled={this.state.replyText.length > 0 || this.state.imgSrcV2.length>0?0:1} style={{marginRight:"0px"}} onClick={this.state.replyText.length > 0 || this.state.imgSrcV2.length>0? (e) => this.addReply(e, id):null}>
              <img src={ImageUrl + "/social-feed/sendButton.png"} />
            </SendButton>
          </div>
        </RecognitionSection>
        {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.onEmojiSelect} hidePicker={this.showEmojiPicker} isCommentModal={this.props.isCommentModal}></SocialFeedsEmojiPicker> : null}
      </TextFieldContainer>
    );
  };

  renderCommentArea = (value, id, name) => {
    const { enableTrigger } = this.props;
    if (name && !this.state.replyText && this.state.initialReply) {
      this.setState({
        initialReply:false,
        tagUserName:name
      })
      this.onChangeValue(false, name);
    }
    return (
      <>
        <CommentBoxWrap width={"100% - 60px"} style={{marginTop:"8px", marginLeft:"60px"}}>
          <div className='profile-picture'>
            {this.showProfileImage('', true)}
          </div>
          <ReplyTextPopup border={this.state.replyText?.length > 499} width={this.state.replyText?.length > 0?"100% !important":"80%"}>
            <ReplySectionTextArea
              replyCommentName={name}
              userCompany={this.props.userCompany}
              addInspiration={this.onChangeValue}
              inspirationQuote={value}
              updateText={this.updateText}
              users={this.state.users}
              userIdArray={this.state.userIdArray}
              placeholder={" Write your reply"}
              showGivenShoutout={this.state.showGivenShoutout}
              updateShoutoutArray={this.updateShoutoutArray}
              emptyShoutoutArray={this.emptyShoutoutArray}
              onRemoveShoutout={this.onRemoveShoutout}
              isCommentOrReply={"isCommentOrReply"}
              shoutoutText={this.state.shoutoutText}
              shoutoutImage={this.state.shoutoutImage}
              taggedUser={this.state.taggedUser}
              onRemoveUser={this.onRemoveUser}
              enableTrigger={enableTrigger}
              ShowEmojiContainer={this.state.ShowEmojiContainer}
              myref={this.myref}
              showEmojiPicker={this.showEmojiPicker}
              displayEmojiPicker={this.state.displayEmojiPicker}
              closeEmojiContainer={this.displayEmojiContainer}
              emojiRef={this.emojiref}
              comments={this.props.comments}
              selectedImage={this.state.imgSrcV2[0]}
              removeImage={this.removeImage}
              addInspirationV2={this.addInspirationV2}
              isReplyTextArea={"1"}
            />
            {this.state.replyText?.length > 0?
              <IconContainer width="100% !important" cameraIcon style={{ width:"100% !important",justifyContent: "space-between", position:"initial", display: "flex", margin: "12px auto auto auto" }}>
                <div style={{width:"24px", height:"24px", display:"flex"}}>
                  <img style={{ marginTop: '0px', cursor: 'pointer', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/emojiicon.svg"}></img>
                </div>
                <div className='sendbutton' onClick={(e)=>this.addReply(e, id)}>
                  Reply
                </div>
              </IconContainer>:
              <IconContainer cameraIcon style={{ width:"20% !important",justifyContent: "flex-end", position:"initial", marginTop:"0px", display: "flex", margin: "auto 0 auto auto" }}>
                <div style={{width:"24px", height:"24px", display:"flex", borderRadius:"50%"}}>
                  <img style={{ marginTop: '0px', cursor: 'pointer', margin:"auto" }} onClick={this.showEmojiPicker} src={ImageUrl+"/social-feed/emojiicon.svg"}></img>
                </div>
              </IconContainer>}
          </ReplyTextPopup>
        </CommentBoxWrap>
        {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.onEmojiSelect} hidePicker={this.showEmojiPicker} isCommentModal={this.props.isCommentModal}></SocialFeedsEmojiPicker> : null}
      </>
    )
  }

  getTaggedUserId = (e) => {
    const { history, userId, fetchUserPosts } = this.props;
    fetchUserPosts(e.target.id, history);
    if (e.target.id) {
      if (userId === e.target.id) {
        history.push('/profile');
      } else {
        history.push(`/profile/${e.target.id}`);
      }
    }
  };

  likePost = () => {
    this.setState((prev) => ({
      likeStatus: !prev.likeStatus
    }))
  }

  changeDeleteCommentPopup = (value, id) => {
    this.setState({ showDeleteCommentPopup: value, deleteCommentId: id });
  };

  openDeleteComment = () => {
    this.setState({
      DeleteComment: !this.state.DeleteComment
    })
  };


  onHideDeleteCommentPopup = () => {
    this.setState({
      showDeleteCommentPopup: ''
    }, () => {
      this.openDeleteComment();
    })
  };

  render() {
    const { comments, fixHeight, noFloat, showThreeComments, t } = this.props;
    const { replyTextField, replyText, replyCount } = this.state;
    return (
      <CommentsContainer noFloat={noFloat}>
        <FieldContainer fixHeight={fixHeight} commentsSection noFloat={noFloat} >
          {
            showThreeComments ? comments && comments.map((comment, index) => {
              const TimeOfComment = convertMonthFromDate(moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMMM DD'), this.props);
              const chunkOfReply = (!_.isEmpty(comment.replies) ? comment && comment.replies && comment.replies.slice(0, replyCount) : []);
              const translatedComment = comment.comment;
              window.console.log("line 565 translated comment :- ", translatedComment);
              return (
                <SingleComment key={index} noFloat={noFloat}>
                  <CommentContent noFloat>
                    <ImageWrapper comment={1}>
                      {this.showProfileImage(comment, false)}
                    </ImageWrapper>
                    <CommentSection>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }} className={"profileName"}>
                        <div>{t(comment.fullname)}</div>
                        {this.props.userId === comment.uid && <DownAngle background={1} style={{ lineHeight: '0px' }} feed>
                          <DropdownButton id="buddy" title={<img src='/public/images/menuIcon.png' style={{width:"24px", height:"auto"}}/>}>
                            <Dropdown.Item onClick={() => this.changeDeleteCommentPopup('delete', comment.id)}>
                              {t("Delete Reply")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </DownAngle>}
                      </div>
                      {!this.props.isChallengeOrEvent ? <React.Fragment>{ (!_.isNull(comment.core_value)&&!_.isNull(comment.shoutout_user_name)) &&<RecognitionText padding={'0px'} style={{ display: 'block', paddingBottom: "0px" }}>
                        {!_.isNull(comment.core_value) && <span className="first-span">
                          {t("Giving")}
                          <Img src={`${ImageUrl}/${comment.recognition_image}`} />
                          {this.props.t(comment.core_value)} {t("to")}
                        </span>}
                        {!_.isNull(comment.shoutout_user_name) && <span className="second-span">
                          {!_.isNull(comment.shoutout_user_name) ?
                            // <span dangerouslySetInnerHTML={{ __html: convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0] }} /> : null
                            <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(comment.shoutout_user_name.replace(/￼/g, "")).split('-')[0]))}</span> : null
                          }
                        </span>}
                      </RecognitionText>}
                      {/* <span className="CommentDescription" dangerouslySetInnerHTML={{ __html: getDecodedString(comment.comment).split('\\n').join('<br />') }} /> */}
                      <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      </React.Fragment>
                        :
                        // <span className="CommentDescription" dangerouslySetInnerHTML={{ __html: getDecodedString(comment.comment).split('\\n').join('<br />') }} />
                        <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                      }
                      {
                        comment.image ? <div className="image"  style={{marginTop:"10px"}}>
                          <Img src={`${ImageUrl}/${comment.image}`} />
                        </div> : null
                      }
                    </CommentSection>
                    <div style={{width:"100%"}}>
                      <StyledLink noFloat={noFloat} width >
                        <Img disable={this.state.clicked} onClick={() => this.likeUnlikePost(comment.id, comment.own_like_post)} src={comment.own_like_post ? ImageUrl+"/social-feed/like_filed.png" : ImageUrl+"/social-feed/like.png"} />&nbsp;
                        {comment.comment_like_count !== 0 && <div>{comment.comment_like_count}</div>}
                        <div onClick={() => this.showTextField(comment.id,comment.fullname)}>&nbsp;| {t("Reply")} {chunkOfReply.length > 0 ? `. ${chunkOfReply.length}` : ''}</div>
                        <TimeContent  fullWidth={replyTextField === comment.id} marginTop width style={{width:"auto"}}>{t(TimeOfComment)}</TimeContent>
                      </StyledLink>
                    </div>
                    {replyTextField === comment.id && this.renderTextArea(replyText, comment.id, comment.fullname)}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMMM DD');
                        return (
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <ImageWrapper comment={1}>
                              {this.showProfileImage(reply, false)}
                            </ImageWrapper>
                            <CommentSection isReply noFloat={noFloat}>
                              <div className={"profileName"}>
                                {reply.fullname}
                              </div>
                              {!this.props.isChallengeOrEvent ? <RecognitionText padding={'0px'}>
                                {!_.isNull(reply.core_value) && <span className="first-span">
                                  {t("Giving")}
                                  <Img src={`${ImageUrl}/${reply.recognition_image}`} />
                                  {this.props.t(reply.core_value)} {t("to")}
                                </span>}
                                {!_.isNull(reply.shoutout_user_name) && <span className="second-span">
                                  {
                                    !_.isNull(reply.shoutout_user_name) ?
                                      // <span dangerouslySetInnerHTML={{ __html: convertUnicode(reply.shoutout_user_name).split('-')[0] }} /> : null
                                      <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name).split('-')[0]))}</span> : null
                                  }
                                </span>}
                                {/* <span className="CommentDescription" dangerouslySetInnerHTML={{ __html: getDecodedString(reply.reply).split('\\n').join('<br />') }} /> */}
                                <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                              </RecognitionText> :/* <span className="CommentDescription" dangerouslySetInnerHTML={{ __html: getDecodedString(reply.reply).split('\\n').join('<br />') }} /> */ <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>}
                              {
                                reply.image ? <div className="image">
                                  <Img src={`${ImageUrl}/${reply.image}`} />
                                </div> : null
                              }

                            </CommentSection>
                            <div style={{width:"100%"}}>
                              <StyledLink noFloat={noFloat} width >
                                <Img disable={this.state.clicked} onClick={() => this.likeUnlikePostCommentReply(reply.id, reply.own_like_post)} src={reply.own_like_post ? ImageUrl+"/social-feed/like_filed.png" : ImageUrl+"/social-feed/like.png"} />&nbsp;
                                {reply.reply_like_count !== 0 && <div>{reply.reply_like_count}</div>}
                                <div onClick={() => this.showTextField(reply.id,reply.fullname)}>&nbsp;| {t("Reply")}</div>
                                <TimeContentV2 fullWidth marginTop width style={{width:"auto"}}>{t(TimeOfReply)}</TimeContentV2>

                              </StyledLink>
                            </div>
                            {replyTextField === reply.id && this.renderTextArea(replyText, comment.id, comment.fullname)}
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&
                      <ViewMoreLink isReply onClick={() => this.showMoreReplies(comment.replies.length)}>
                        {t("View More Replies")}
                      </ViewMoreLink>
                    }
                  </CommentContent>
                </SingleComment>
              )
            }) : comments && comments.map((comment, index) => {
              const TimeOfComment = convertMonthFromDate(moment(convertDateInTimezone(comment.created_at)._d).format('hh:mma, MMM DD'), this.props);
              const chunkOfReply = (!_.isEmpty(comment.replies) ?comment && comment.replies && comment.replies.slice(0, replyCount) : []);
              return (
                <SingleComment key={index} noFloat={noFloat}>
                  <CommentContent noFloat={noFloat}>
                    <CommentsWrapper>
                      <div className='profile'>
                        {this.showProfileImage(comment, false)}
                      </div>
                      <div className='commentwrap'>
                        <div className='header'>
                          <div className='name'>{t(comment.fullname)}</div>
                          <div className='menu'>
                            {this.props.userId === comment.uid && <DownAngleV2 margin feed>
                              <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} style={{width:"24px", height:"auto", background:"none !important"}}/>}>
                                <Dropdown.Item onClick={() => this.changeDeleteCommentPopup('delete', comment.id)} >
                                  {t("Delete")}
                                </Dropdown.Item>
                              </DropdownButton>
                            </DownAngleV2>}
                          </div>
                        </div>
                        <div className='comment'>
                          {/* {!_.isNull(comment.shoutout_user_name) && <span className="second-span" style={{marginLeft:"0px"}}>
                            {!_.isNull(comment.shoutout_user_name) ?
                              <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(comment?.shoutout_user_name?.replace(/￼/g, "")).split('-')[0]))}</span> : null
                            }
                          </span>} */}
                          <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment.comment).split('\\n').join('<br />')))}</span>
                          {
                            comment.image ? <div className="image">
                              <Img src={`${ImageUrl}/${comment.image}`} />
                            </div> : null
                          }
                        </div>
                        <div className='timelikecomment'>
                          <div className='time'>{t(TimeOfComment)}</div>
                          <div className='likecomment'>
                            <Img disable={this.state.clicked} onClick={() => this.likeUnlikePost(comment.id, comment.own_like_post)} src={comment.own_like_post ? ImageUrl+"/social-feed/like_filed.svg" : ImageUrl+"/social-feed/like.svg"} />
                            {comment.comment_like_count !== 0 && <span>{comment.comment_like_count}</span>}
                            <span>|</span>
                            <span onClick={() => this.showTextField(comment.id,comment.fullname)}>Reply</span>
                            <span>.</span>
                            <img src={ImageUrl+"/social-feed/commentpopup.svg" }/>
                            {chunkOfReply?.length>0&&<span>{chunkOfReply?.length}</span>}
                          </div>
                        </div>
                      </div>
                    </CommentsWrapper>
                    {replyTextField === comment.id && this.renderCommentArea(replyText, comment.id, comment.fullname)}
                    {this.state.replyText?.length >499&&<MaxText>You have exceeded the maximum character limit</MaxText>}
                    {/* {this.state.displayEmojiPicker ? <SocialFeedsEmojiPicker comment selectEmoji={this.onEmojiSelect} hidePicker={this.showEmojiPicker} isCommentModal={this.props.isCommentModal}></SocialFeedsEmojiPicker> : null} */}
                    {
                      chunkOfReply.map((reply, index) => {
                        const TimeOfReply = moment(convertDateInTimezone(reply.created_at)._d).format('hh:mma, MMM DD');
                        return (
                          <CommentContent key={reply.reply + index} isReply noFloat={noFloat}>
                            <CommentsWrapper padding>
                              <div className='profile'>
                                {this.showProfileImage(reply, false)}
                              </div>
                              <div className='commentwrap'>
                                <div className='header'>
                                  <div className='name'>{t(reply.fullname)}</div>
                                </div>
                                <div className='comment'>
                                  {/* {!_.isNull(reply.shoutout_user_name) && <span className="second-span">
                                    {
                                      !_.isNull(reply.shoutout_user_name) ?
                                        <span>{DOMPurify.sanitize(this.createSafeHTML(convertUnicode(reply.shoutout_user_name).split('-')[0]))}</span> : null
                                    }
                                  </span>} */}
                                  <span className="CommentDescription">{DOMPurify.sanitize(this.createSafeHTML(getDecodedString(reply.reply).split('\\n').join('<br />')))}</span>
                                  {
                                    reply?.image ? <div className="image">
                                      <Img src={`${ImageUrl}/${reply?.image}`} />
                                    </div> : null
                                  }
                                </div>
                                <div className='timelikecomment'>
                                  <div className='time'>{t(TimeOfReply)}</div>
                                  <div className='likecomment'>
                                    <img disable={this.state.clicked} onClick={() => this.likeUnlikePostCommentReply(reply.id, reply.own_like_post)} src={reply.own_like_post ? ImageUrl+"/social-feed/like_filed.png" : ImageUrl+"/social-feed/like.png"} />
                                    {reply.reply_like_count !== 0 && <span>{reply.reply_like_count}</span>}
                                    <span onClick={() => this.showTextField(reply.id, reply.fullname)}>Reply</span>
                                  </div>
                                </div>
                              </div>
                            </CommentsWrapper>
                            {replyTextField === reply.id && this.renderCommentArea(replyText, comment.id, comment.fullname)}
                          </CommentContent>
                        )
                      })
                    }
                    {
                      (!_.isEmpty(comment.replies) && comment.replies.length > replyCount) &&
                      <ViewMoreLink isReply onClick={() => this.showMoreReplies(comment.replies.length)}>
                        {t("View More Replies")}
                      </ViewMoreLink>
                    }
                  </CommentContent>
                </SingleComment>
              )
            })
          }
        </FieldContainer>
        {this.state.showDeleteCommentPopup && <DeleteCommentPopup
          showModal={this.state.showDeleteCommentPopup == 'delete'}
          onClose={this.onHideDeleteCommentPopup}
          title={this.props.t("DELETE COMMENT")}
          subContent={this.props.t("This action cannot be undone.")}
          commentId={this.state.deleteCommentId}
          postId={this.props.id}
        />}
      </CommentsContainer>
    )
  }
}

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  fixHeight: PropTypes.bool,
  postReply: PropTypes.func,
  id: PropTypes.number,
  replySuccess: PropTypes.string,
  noFloat: PropTypes.bool,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  history: PropTypes.object,
  userCompany: PropTypes.object,
  isChallengeOrEvent: PropTypes.bool,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  likeUnlikePostComment: PropTypes.func.isRequired,
  disableLikeButton: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  updationList: PropTypes.string,
  likeUnlikeCommentReply: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  showThreeComments: PropTypes.bool,
  renderViewCommentModal: PropTypes.func,
  renderViewCommentAndGetComment: PropTypes.func,
  fromSocialFeed: PropTypes.bool,
  t: PropTypes.func,
  forCommentPopup: PropTypes.bool,
  isCommentModal:PropTypes.bool
};

const mapStateToProps = (state) => ({
  userName: state.profileData.userName,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserName: () => dispatch(getUserName()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history)),
  likeUnlikePostComment: (data, postId) => dispatch(likeUnlikePostComment(data, postId)),
  likeUnlikeCommentReply: (data, postId) => dispatch(likeUnlikeCommentReply(data, postId)),
  disableLikeButton: () => dispatch(disableButton()),
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(Comments));